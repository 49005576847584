/**
 * @tips - Try to use RGB colors instead of Hexa
 * then, just put 50% in BG_PRIMARY_GRADIENT
 */
export const BG_PRIMARY = 'rgb(73 22 37)';
export const BG_PRIMARY_GRADIENT = 'rgb(73 22 37 / 50%)';
export const BG_SECONDARY = 'rgb(203 190 171)';
export const BG_ALTERNATIVE = 'rgb(73 22 37)';
export const BG_ALTERNATIVE_DARK = 'rgb(73 22 37)';

// color text
export const TEXT_PRIMARY = '#cbbeab';
export const TEXT_PRIMARY_LIGHT = '#F5F6E8';
export const TEXT_SECONDARY = '#996211';
export const TEXT_ALTERNATIVE = 'rgb(73 22 37)';
export const TEXT_SHADOW = 'rgb(73 22 37)';

// music & navigation
export const NAVIGATION_COLOR = '#dcd0b2';
export const DRAWER_COLOR = 'rgb(73 22 37)';

// DEFAULT BUTTON PROPS
export const DIVIDER_COLOR = '#cbbeab'; // Wedding detail
export const BUTTON_GRADIENT = `linear-gradient(to left, #cbbeab, #8e8577 140%)`;
export const BUTTON_PROPS = {
  _active: { borderColor: 'transparent' },
  _hover: { borderColor: 'transparent' },
  _focus: { borderColor: 'transparent' },
  color: 'rgb(73 22 37)',
};
export const DEFAULT_OPTIONS_PROPS = {
  style: { color: 'black' },
};
export const DEFAULT_BUTTON_PROPS = {};
